.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 16px 36px;
  background-color: #ffffff;

  &_dropdown {
    max-width: 300px;
    min-width: 100px;
    width: 100%;
  }
}
