.link {
  display: inline-flex;
  align-items: flex-end;
  line-height: 28px;
  font-size: 22px;
  color: rgba(64, 64, 64, 0.85);
  margin-top: auto;
  text-decoration: none;
  gap: 8px;

  &_arrow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &_back {
    flex-direction: row-reverse;
    .link_arrow {
      transform: rotate(180deg);
    }
  }

  .svg {
    height: 24px;
  }
}
