@import "../../../styles/themes";
@import "../../../styles/typography";
@import "../../../styles/global-css-settings";

.accordion {
  height: fit-content;
  border: 1px solid var(--ui-border-color);
  box-sizing: border-box;
  border-radius: $border-radius-small;
}

.accordion .accordionItemExpandCollapse {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  @include theme-prop(border-color, ui-border-color);
}

.accordion .accordionItemExpandCollapseLast {
  border-bottom: none;
}
