@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.headingComponent {
  border: 1px solid transparent;
  border-radius: $border-radius-small;
  min-width: 0;
  @include heading-padding();
}

.headingComponent.multiLineEllipsis {
  @include line-clamp(var(--heading-clamp-lines));
}

/* This is needed to support ellipsis in the middle of the word */

.headingComponent.singleLineEllipsis {
  @include ellipsis();
}

.headingComponent.suggestEditOnHover:hover {
  cursor: text;
  border: 1px solid;
  @include theme-prop(border-color, ui-border-color);
}

/* Override tags styles */

.elementTypeH1 {
  letter-spacing: -0.2px;
  font-size: $h1-legacy-font-size;
  line-height: $h1-legacy-line-height;
  font-weight: var(--font-weight-bold);
  font-family: var(--title-font-family);
  -webkit-font-smoothing: var(--font-smoothing-webkit);
  -moz-osx-font-smoothing: var(--font-smoothing-moz);
}

.elementTypeH1.sizeSmall,
.elementTypeH1.sizeMedium {
  font-size: var(--font-size-h2);
  line-height: var(--font-line-height-h2);
}

.elementTypeH2 {
  letter-spacing: -0.2px;
  @include font-title();
}

.elementTypeH2.sizeSmall,
.elementTypeH2.sizeMedium {
  @include font-subtitle();
}

.elementTypeH3 {
  letter-spacing: -0.2px;
  @include font-title-secondary();
}

.elementTypeH3.sizeSmall,
.elementTypeH3.sizeMedium {
  @include font-subtitle();
}

.elementTypeH4 {
  letter-spacing: -0.2px;
  @include font-subtitle();
}

.elementTypeH5 {
  @include font-paragraph-bold();
}

.elementTypeH6 {
  @include font-general-text();
}

.elementTypeP {
  @include font-paragraph();
}

.elementTypeSmall {
  @include font-caption();
}

.elementTypeLink {
  @include font-link();
}
