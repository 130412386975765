@import "../../styles/themes";
@import "../../styles/typography";

.textWithHighlightWrapper {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.textWithHighlightWrapper em.highlightText {
  @include theme-prop(background-color, primary-selected-color);
  @include theme-prop(color, primary-text-color);
  border-radius: 2px;
}

.textWithHighlightWrapper.withEllipsis {
  @include line-clamp(var(--heading-clamp-lines));
}
