@import "../../../../styles/global-css-settings";
@import "../../../../styles/typography";
@import "../../../../styles/themes";

.clearColorIcon {
  margin-right: $spacing-small;
}

.clearColorButton {
  margin-top: $spacing-small;
}