@import "../../styles/typography";
@import "../../styles/themes";
@import "../../styles/global-css-settings";
@import "../../styles/states";

.steps {
  .contentOnTop {
    margin-top: var(--spacing-medium);
  }
  .contentOnBottom {
    margin-bottom: var(--spacing-small);
  }
}
