@import "../../styles/themes";
@import "../../styles/typography";

.hiddenTextWrapper {
  /* clip pattern taken from https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/. */
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
