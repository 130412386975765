@import "../../../styles/themes";
@import "../../../styles/typography";
@import "../../../styles/states";
@import "../../../styles/global-css-settings";

.item {
  margin: unset;
  @include font-default;
  display: flex;
  flex-direction: row;
  padding: $spacing-xs $spacing-small;
  @include theme-prop(color, primary-text-color);
  cursor: pointer;
  user-select: none;
  align-items: center;
  border: 1px solid transparent;
  outline: none;
}

.iconWrapper {
  margin-right: $spacing-small;
}

.subMenuIconWrapper {
  margin-left: $spacing-xs;
}

.subMenuIconWrapper,
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subMenuIconWrapper .subMenuIcon,
.subMenuIconWrapper .icon,
.iconWrapper .subMenuIcon,
.iconWrapper .icon {
  @include theme-prop(color, icon-color);
}

.item.disabled {
  cursor: not-allowed;
  @include theme-prop(color, disabled-text-color);
}

.item.disabled .subMenuIcon,
.item.disabled .icon {
  cursor: not-allowed;
  @include theme-prop(color, disabled-text-color);
}

.item:not(.disabled).focused {
  @include theme-prop(background-color, primary-background-hover-color);
}

.item:not(.disabled).focused:not(:hover):not(.initialSelected) {
  @include focus-style-css();
}

.item:not(.disabled).focused {
  border-radius: var(--border-radius-small);
}

.item:not(.disabled).selected {
  @include theme-prop(background-color, primary-selected-color);
  border-radius: var(--border-radius-small);
}

.item:not(.disabled).selected .iconWrapper .icon {
  @include theme-prop(color, primary-color);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 0;
  @include font-caption();
}
