@import "../../../styles/themes";

.track {
  position: absolute;
  border-radius: inherit;
  @include theme-prop(background-color, primary-selected-color);
  height: inherit;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.positive {
  @include theme-prop(background-color, positive-color-selected);
}

.negative {
  @include theme-prop(background-color, negative-color-selected);
}
