.home-page {
  display: flex;
  flex-direction: column;

  &_banner {
    background-image: url('../../assets/homeBanner.svg');
    background-size: cover;
    height: 300px;
    width: 100%;
    background-color: #ffffff;
  }

  &_text {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 800;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.06em;
    padding: 0 24px;
    color: rgba(64, 64, 64, 0.8);
  }

  &_cards {
    margin: 36px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

