@import "../../styles/themes";
@import "../../styles/typography";

.listItemIcon {
  display: flex;
  align-items: center;
  color: var(--primary-text-color);
}

.start {
  margin-right: var(--spacing-small);
}

.end {
  margin-left: var(--spacing-small);
}
