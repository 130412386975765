.info-page {
  padding: 36px;
  background-color: #ffffff;
  height: 100%;

  &_title {
    margin: 0;
    font-weight: 800;
    color: rgba(64, 64, 64, 0.85);
    font-size: 34px;
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    gap: 46px;
  }

  &_description {
    font-size: 22px;
    flex: 1;
    color: #404040;
  }

  &_buttons {
    display: flex;
    gap: 16px;
  }

  &_image {
    flex: 1;
    width: 50%;

    video {
      width: 100%;
      height: 100%;
    }
  }

  &_main {
    img {
      width: 40%;
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

