@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.dropdown :global(.fast-option) {
  display: flex;
  align-content: center;
  @include theme-prop(color, primary-text-color);
  cursor: pointer;
  border-radius: $border-radius-small;
  margin: 0 var(--spacing-small);
  padding: 0 var(--spacing-small);
  transition: all var(--motion-productive-medium) var(--motion-timing-enter);
}

.dropdown :global(.fast-option):disabled {
  background-color: transparent;
  @include theme-prop(color, disabled-text-color);
  cursor: not-allowed;
}

.dropdown :global(.fast-option-disabled) {
  background-color: transparent;
  @include theme-prop(color, disabled-text-color);
  cursor: not-allowed;
}

.dropdown :global(.fast-option-focused) {
  @include theme-prop(color, primary-text-color);
  @include theme-prop(background-color, primary-selected-color);
  user-select: none;
  box-sizing: border-box;
}

.dropdown :global(.fast-option-focused):hover {
  @include theme-prop(color, primary-text-color);
  @include theme-prop(background-color, primary-background-hover-color);
}

.dropdown :global(.fast-option-selected) {
  @include theme-prop(color, primary-color);
  @include theme-prop(background-color, primary-selected-color);
  cursor: pointer;
}
