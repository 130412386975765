@import "../../styles/typography";
@import "../../styles/themes";
@import "../../styles/global-css-settings";
@import "../../styles/states";

@mixin active-step-dot {
  @include theme-prop(background, primary-color);
  transform: scale(1.3);
}

.dot {
  @include reset-button;
  width: 6px;
  height: 6px;
  cursor: pointer;
  border-radius: 50%;
  @include theme-prop(background, ui-border-color);
  @include focus-style;
  transform-origin: center;
  transform: scale(1);
  transition: transform var(--motion-productive-long) var(--motion-timing-transition),
    background var(--motion-productive-long) var(--motion-timing-transition);

  &:hover {
    transform: scale(1.3);
  }
}

.isActive {
  @include active-step-dot;
}

.onPrimary.dot {
  @include theme-prop(background, primary-hover-color);
  @include focus-style-on-primary;
}

.onPrimary.isActive {
  @include theme-prop(background, text-color-on-primary);
}
