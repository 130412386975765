@import './variables.scss';

.app {
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  max-width: $containerMaxWidth;
  height: 100vh;

  &_content {
    flex-grow: 1;
  }

  &_loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
