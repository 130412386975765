.card {
  display: flex;
  gap: 48px;
  padding: 36px;
  border-radius: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #ffffff;
  min-height: 350px;

  &_block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 1;
    color: rgba(64, 64, 64, 0.85);
  }

  &_title {
    margin: 0;
    font-weight: 800;
    font-size: 34px;
  }

  &_description {
    font-size: 22px;
  }

  &_link {
    font-size: 22px;
    color: rgba(64, 64, 64, 0.85);

    margin-top: auto;
  }

  &_img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    video {
      width: 100%;
      height: 100%;
    }
  }
}
