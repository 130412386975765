@import "../../../styles/themes";
@import "../../../styles/typography";
@import "../../../styles/global-css-settings";

.itemButton {
  margin: unset;
  @include font-default;
  display: flex;
  flex-direction: row;
  padding: var(--spacing-xs) 0;
  align-items: center;
}

.itemButton .buttonComponent {
  width: 100%;
}

.itemButton .buttonComponent .content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
