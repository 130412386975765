@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.labelComponentWrapper {
  display: flex;
  padding-bottom: var(--spacing-xs);
  align-items: center;
}

.labelComponentIcon {
  margin: 0 var(--spacing-small) 0 var(--spacing-xs);
  line-height: 24px;
  font-size: var(--font-size-general-label);
  @include theme-prop(color, icon-color);
  font-family: var(--font-family);
}

.labelComponentText {
  font: var(--font-general-label);
  @include theme-prop(color, primary-text-color);
}
