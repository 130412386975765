@import "../../../styles/global-css-settings";
@import "../../../styles/states";
@import "../../../styles/themes";

.thumb {
  position: absolute;
  border: 3px solid;
  border-radius: 50%;
  @include theme-prop(border-color, primary-color);
  @include theme-prop(background-color, primary-background-color);
  font-size: 14px;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform var(--motion-productive-long) var(--motion-timing-enter);
  font-family: var(--font-family);
}

.dragging {
  cursor: grabbing !important;
  transform: translate(-50%, -50%) scale(1.33, 1.33);
  transform-origin: center center;
  transition: transform var(--motion-productive-long) var(--motion-timing-enter);
  @include box-shadow-small();
}
.focused {
  @include theme-prop(border-color, primary-hover-color);
  cursor: grab;
  @include box-shadow-small();
}

// thumb (for NOT-disabled)
.notDisabledThumb {
  @include focus-style(50%);
  &:hover {
    @include theme-prop(border-color, primary-hover-color);
    cursor: grab;
    @include box-shadow-small();
  }
}

.positive {
  @include theme-prop(border-color, positive-color);
}

.positive.notDisabledThumb {
  &:hover {
    @include theme-prop(border-color, positive-color-hover);
  }
}

.negative {
  @include theme-prop(border-color, negative-color);
}

.negative.notDisabledThumb {
  &:hover {
    @include theme-prop(border-color, negative-color-hover);
  }
}

.small {
  height: 16px;
  width: 16px;
}

.medium {
  height: 24px;
  width: 24px;
}

.large {
  height: 24px;
  width: 24px;
}

.label {
  bottom: calc(100% + 8px);
  left: 50%;
  max-width: 50px;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  transform: translate(-50%, 0);
  white-space: nowrap;
}
