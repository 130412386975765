@import "../../../../styles/themes";
@import "../../../../styles/typography";
@import "../../../../styles/global-css-settings";
@import "../../../../styles/states";
$icon-margin: 4px;

.comboboxOption {
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}

.comboboxOption.disabled {
  cursor: not-allowed;
  @include disabled;
}

.comboboxOption.selected {
  @include theme-prop(background-color, primary-selected-color);
}

.comboboxOption:hover:not(.disabled):not(.selected),
.comboboxOption.active {
  @include theme-prop(color, primary-text-color);
  @include theme-prop(background-color, primary-background-hover-color);
  position: relative;
}

.comboboxOption.activeOutline {
  @include focus-style-css-inset();
  background-color: var(--secondary-background-color);
}

.comboboxOption .optionLabel {
  flex: 1 1 auto;
  @include ellipsis();
}

.comboboxOption .optionIcon {
  flex: 0 0 auto;
}

.comboboxOption .optionIcon.left {
  margin-right: $icon-margin;
}
