@import "../../styles/themes";

.slider {
  align-items: center;
  display: flex;
  max-width: 500px;
  min-width: 200px;
  width: 100%;
}

.valueShown {
  margin-top: 10px;
}
